import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import array from "lodash/array";
import lang from "lodash/lang";
import { DateTime } from "luxon";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";
import {
  getLayer,
  buildIntervalFilter,
  buildHardwareFilter,
  paginatedQuery,
} from "./mapUtils";
import { LORA_TRACKING_LAYER, AGO_DATETIME_FORMAT } from "./../../config";

// TODO: reconsider what get's stored in the Map slice vs filterMenu slice
// The filterMenu component draws devices and gateways from the map slice &
// it also dispatches filtering thunks to map slice. IDK - maybe that's ok?

const initialState = {
  mapViewInitialized: false,
  lastTransmission: DateTime.utc().setZone("UTC").toString(),
  devices: [],
  gateways: [],
  labels: [],
  clicks: 0,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    mapViewInitialized: (state) => {
      state.mapViewInitialized = true;
    },

    checkboxToggled: (state, { payload }) => {
      // console.log("checkboxToggled: ", payload);
      const { id, filterType } = payload;
      state[filterType] = state[filterType].map((item) => {
        item.checked = item.id === id ? !item.checked : item.checked;
        return item;
      });
    },

    mapClicked: (state) => {
      state.clicks = state.clicks + 1;
      // console.log("Clicked", state.clicks, "times");
    },

    logSomething: (state, { payload }) => {
      // console.log("SOMETHING", payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetaData.fulfilled, (state, { payload }) => {
        // set only if valid
        // console.log("PAYLOAD", payload);
        if (payload) state.lastTransmission = payload;
      })
      .addCase(getDevicesAndGateways.fulfilled, (state, { payload }) => {
        const { devices, gateways } = payload;
        state.devices = devices.map((dev) => ({
          id: dev.id,
          label: dev.label,
          checked: true,
        }));
        state.gateways = gateways.map((gtw) => ({
          id: gtw.id,
          label: gtw.label,
          checked: true,
        }));
      });
  },
});

export const { mapViewInitialized, mapClicked, logSomething, checkboxToggled } =
  mapSlice.actions;

export const filterByTime = createAsyncThunk(
  "map/filterByTime",
  async ({ view, beginDateTime, endDateTime }) => {
    // NOTE: using definitionExpression on the FeatureLayer to apply time
    // interval filter. This should in theory perform server-side filtering:
    // https://developers.arcgis.com/javascript/latest/query-filter/#server-side-filtering
    console.log(beginDateTime);
    console.log(endDateTime);
    if (view) {
      try {
        const loraLayer = getLayer(view, "Lora tracking");
        const intervalFilter = buildIntervalFilter(beginDateTime, endDateTime);
        // console.log("BEFORE INTERVAL FILTER: ", beginDateTime, endDateTime);
        // console.log("TIME INTERVAL FILTER: ", intervalFilter);
        loraLayer.definitionExpression = intervalFilter;
      } catch (err) {
        // TODO: Add error handling (e.g. dispatch queryLayerFailure())
        console.log("error filtering transmisions by time interval: ", err);
      }
    }
  }
);

export const filterByHardware = createAsyncThunk(
  "map/filterByHardware",
  async ({ view, gateways, devices }) => {
    // NOTE: using LayerView.filter to apply hardware filter on the client-side.
    if (view) {
      try {
        const loraLayer = getLayer(view, "Lora tracking");
        const filter = buildHardwareFilter(gateways, devices);
        console.log("hardware filter: ", filter);
        let layerView = await view.whenLayerView(loraLayer);
        layerView.filter = new FeatureFilter({ where: filter });
      } catch (err) {
        // TODO: Add error handling (e.g. dispatch queryLayerFailure())
        console.log(
          "DEBUG: error filtering transmisions by device and gateway: ",
          err
        );
      }
    }
  }
);

export const getMetaData = createAsyncThunk("map/getMetadata", async (view) => {
  // NOTE: using FeatureLayer.queryFeatures() to perform query server-side
  if (view) {
    const loraLayer = getLayer(view, "Lora tracking");
    const query = loraLayer.createQuery();
    query.outStatistics = [
      {
        onStatisticField: "rec_tm_utc",
        outStatisticFieldName: "time_last",
        statisticType: "max",
      },
    ];
    try {
      // console.log("QUERY", query);
      const lastTrans = await loraLayer.queryFeatures(query);
      // console.log("METADATA QUERY RES", lastTrans.features[0].attributes);
      // console.log("DEBUG: result", lastTrans.features[0].attributes);
      let lastTransTime = lastTrans.features[0].attributes["time_last"];
      // console.log("TRANS TIME FROM AGO", lastTransTime);
      lastTransTime = DateTime.fromFormat(lastTransTime, AGO_DATETIME_FORMAT, {
        zone: "UTC",
      });
      // console.log("LAST TRANS TIME", lastTransTime);
      // that is a quick fix and needs to be impproved
      return lastTransTime.plus({ seconds: 1 }).toString();
    } catch (err) {
      console.log("DEBUG: error getting metadata: ", err);
    }
  }
});

export const getDevicesAndGateways = createAsyncThunk(
  "map/getDevicesAndGateways",
  async ({ view, beginDateTime, endDateTime }) => {
    if (view) {
      const loraLayer = getLayer(view, "Lora tracking");
      try {
        // TODO: use as replacement
        let devices = [];
        let gateways = [];
        const intervalFilter = buildIntervalFilter(beginDateTime, endDateTime);
        const transmissions = await paginatedQuery(loraLayer, intervalFilter);
        transmissions.forEach((trns) => {
          devices.push({
            id: trns.attributes.dev,
            label: trns.attributes.label,
          });
          for (let i = 1; i < 6; i++) {
            const idAt = "gateway_" + i.toString();
            const labelAt = "gw_label_" + i.toString();
            if (trns.attributes[idAt]) {
              gateways.push({
                id: trns.attributes[idAt],
                label: trns.attributes[labelAt],
              });
            }
          }
        });
        // console.log(devices);
        return {
          devices: array.uniqWith(devices, lang.isEqual).sort(),
          gateways: array.uniqWith(gateways, lang.isEqual).sort(),
        };
      } catch (err) {
        console.log("error getting devices and gateways: ", err);
      }
    }
  }
);

export const selectMapViewInitialized = (state) => state.map.mapViewInitialized;
export const selectLastTransmission = (state) => state.map.lastTransmission;
export const selectDevices = (state) => state.map.devices;
export const selectGateways = (state) => state.map.gateways;
export const selectLabels = (state) => state.map.labels;
export const selectMapClicks = (state) => state.map.clicks;

export default mapSlice.reducer;
