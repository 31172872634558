import { styled } from "../theme/stitches.config.js";
import { violet, blackA } from "@radix-ui/colors";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  backgroundColor: "white",
  width: 15,
  height: 15,
  borderRadius: 2,
  border: `1px solid ${blackA.blackA7}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&[data-state=checked]": {
    backgroundColor: violet.violet5,
  },
  "&[data-state=unchecked]": {
    backgroundColor: violet.violet2,
  },
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": {
    outline: "none",
    boxShadow: `0 0 0 2px ${violet.violet7}`,
  },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: violet.violet11,
});

const Flex = styled("div", { display: "flex" });

const Label = styled("label", {
  color: "black",
  fontSize: 15,
  lineHeight: 1,
  userSelect: "none",
});

const SubLabel = styled("sublabel", {
  color: "black",
  fontSize: 15,
  lineHeight: 1,
  userSelect: "none",
});

export const Checkbox = ({ id, label, sublabel, checked, onChange }) => (
  <Flex css={{ alignItems: "center", marginBottom: 6 }}>
    <StyledCheckbox
      onCheckedChange={onChange}
      checked={checked}
      value={id}
      id={id}
    >
      <StyledIndicator>
        <CheckIcon />
      </StyledIndicator>
    </StyledCheckbox>
    <Label css={{ paddingLeft: 12 }} htmlFor={id}>
      {label}
    </Label>
  </Flex>
);
