import { globalCss } from "./stitches.config.js";

const reset = {
  "*": {
    margin: 0,
    padding: 0,
  },
  "*, *::before, *::after": {
    boxSizing: "border-box",
  },
  "html, body": {
    height: "100%",
  },
  "img, picture, video, canvas, svg": {
    display: "block",
    maxWidth: "100%",
  },
  "p, h1, h2, h3, h4, h5, h6": {
    overflowWrap: "break-word",
  },
};

const globalStyles = globalCss({
  ...reset,
  "html, body": {
    margin: 0,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", ' +
      '"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", ' +
      '"Helvetica Neue", "sans-serif"',
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
    height: `100%`,
  },

  code: {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New"',
  },

  "#root": {
    height: "100%",
  },
});

export default globalStyles;
