import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkESRILogin, loginESRI, logoutESRI } from "./authAPI";

const initialState = {
  loggedIn: null,
  count: 0,
};

// NOTE TO FALK: this is just personal preference, but I like to keep the 
// createSlice() block at the top of the slice files so you can quicky reference
// slice state and reducers. Feel free to move back if you feel otherwise.

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkLoginStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.loggedIn = true;
          state.userId = action.payload;
        } else {
          state.loggedIn = false;
          state.userId = null;
        }
      })
      .addCase(handleLogin.fulfilled, (state, action) => {
        state.loggedIn = action.payload;
      })
  },
});

// export const { handleLogin } = authSlice.actions;

export const checkLoginStatus = createAsyncThunk(
  "auth/checkLoginStatus",
  async (thunkAPI) => {
    const res = await checkESRILogin();
    return res;
  }
);

export const handleLogin = createAsyncThunk(
  "auth/handleLogin",
  async (loggedIn, thunkAPI) => {
    if (!loggedIn) {
      await loginESRI();
      // we will never get here since we are being redirected
      return true;
    } else {
      await logoutESRI();
      return false;
    }
  }
);

export const selectLoggedIn = (state) => state.auth.loggedIn;
export const selectUserId = (state) => state.auth.userId; 

export default authSlice.reducer;
