import IdentityManager from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import { ESRI_OAUTH_INFO } from "../../config";

const info = new OAuthInfo(ESRI_OAUTH_INFO);

export const checkESRILogin = async () => {
  IdentityManager.registerOAuthInfos([info]);
  try {
    await IdentityManager.checkSignInStatus(info.portalUrl + "/sharing");
    const userId = IdentityManager.credentials[0].userId;
    console.log("USER ID", userId);
    return userId;
  } catch {
    return null;
  }
};

export const loginESRI = async () => {
  IdentityManager.getCredential(info.portalUrl);
};

export const logoutESRI = async () => {
  IdentityManager.destroyCredentials();
};
