import { createSlice } from "@reduxjs/toolkit";
import { radioButtons } from "../../config";
import { DateTime } from "luxon";

const initialState = {
  // use strings in the state object instead of objects
  // seems to be good practice despite the amount of conversions necessary
  beginDateTime: DateTime.utc().minus({ days: 1 }).setZone("UTC").toString(),
  endDateTime: DateTime.utc().setZone("UTC").toString(),
  rangeOptions: radioButtons,
};

export const filterMenuSlice = createSlice({
  name: "filterMenu",
  initialState,
  reducers: {
    rangePicked: (state, { payload }) => {
      const { id, lastTransmission } = payload;
      console.log("rangePicked - id:  ", id);
      console.log("rangePicked - lastTransmission: ", lastTransmission);
      // set selected range option
      state.rangeOptions = state.rangeOptions.map((opt) => {
        opt.selected = opt.id === payload.id;
        return opt;
      });
      // update begin & end date times
      if (id !== "custom") {
        console.log("setting new begin and end time");
        const selectedRange = state.rangeOptions.filter(
          (opt) => opt.id === payload.id
        )[0];
        console.log("rangePicked - duration: ", selectedRange.duration);
        console.log("Last Transmission:", lastTransmission);
        const newBegining = DateTime.fromISO(lastTransmission).minus({
          seconds: selectedRange.duration,
        });
        state.beginDateTime = newBegining.toString();
        state.endDateTime = lastTransmission;
        console.log("Selected range", state.beginDateTime, state.endDateTime);
      }
    },

    beginDateTimePicked: (state, { payload }) => {
      state.beginDateTime = payload;
      // we are setting this to custom whenever picker is used
      state.rangeOptions = state.rangeOptions.map((opt) => {
        opt.selected = opt.id === "custom";
        return opt;
      });
    },

    endDateTimePicked: (state, { payload }) => {
      state.endDateTime = payload;
      // we are setting this to custom whenever picker is used
      state.rangeOptions = state.rangeOptions.map((opt) => {
        opt.selected = opt.id === "custom";
        return opt;
      });
    },
  },
});

export const { beginDateTimePicked, endDateTimePicked, rangePicked } =
  filterMenuSlice.actions;

export const selectRangeOptions = (state) => state.filterMenu.rangeOptions;

export const selectBeginDateTime = (state) => state.filterMenu.beginDateTime;
export const selectEndDateTime = (state) => state.filterMenu.endDateTime;

export default filterMenuSlice.reducer;
