import React from "react";
import { styled } from "../../theme/stitches.config";
import { violet, blackA } from "@radix-ui/colors";
import { useDispatch, useSelector } from "react-redux";
import DateTimePicker from "react-datetime-picker";
import { DateTime } from "luxon";
import { selectLastTransmission } from "../map/mapSlice";
import {
  beginDateTimePicked,
  endDateTimePicked,
  rangePicked,
  selectBeginDateTime,
  selectEndDateTime,
  selectRangeOptions,
} from "./filterMenuSlice";
import {
  RadioGroup,
  RadioGroupRadio,
  RadioGroupIndicator,
} from "../../components/RadioGroup";

const DateSelector = styled("div", {
  marginBottom: 6,

  ".react-datetime-picker__wrapper": {
    border: `1px solid ${blackA.blackA7}`,
    padding: 3,
    backgroundColor: violet.violet2,
    "&:hover": { backgroundColor: violet.violet3 },
  },
});

const Flex = styled("div", { display: "flex" });

const Label = styled("label", {
  color: "black",
  fontSize: 15,
  lineHeight: 1,
  userSelect: "none",
  paddingLeft: 12,
});

const DateSelectorLabel = styled(Label, {
  display: "inline-block",
  paddingLeft: 0,
  marginBottom: 6,
});

export const TimeFilters = () => {
  const dispatch = useDispatch();
  const rangeOptions = useSelector(selectRangeOptions);
  const beginDateTime = useSelector(selectBeginDateTime);
  const endDateTime = useSelector(selectEndDateTime);
  const lastTransmission = useSelector(selectLastTransmission);

  return (
    <div>
      <Flex css={{ justifyContent: "space-between" }}>
        <DateSelector>
          <DateSelectorLabel htmlFor="beginDateTime">
            Begin date
          </DateSelectorLabel>
          <DateTimePicker
            name="beginDateTime"
            value={new Date(beginDateTime)}
            format={"MM/dd/y HH:mm"}
            disableClock={true}
            clearIcon={null}
            calendarIcon={null}
            onChange={(date) => {
              const newBeginDate = DateTime.fromJSDate(date).toString();
              dispatch(beginDateTimePicked(newBeginDate));
            }}
          />
        </DateSelector>
        <DateSelector>
          <DateSelectorLabel htmlFor="endDateTime">End date</DateSelectorLabel>
          <DateTimePicker
            name="endDateTime"
            value={new Date(endDateTime)}
            disableClock={true}
            format={"MM/dd/y HH:mm"}
            clearIcon={null}
            calendarIcon={null}
            onChange={(date) => {
              const newEndDate = DateTime.fromJSDate(date).toString();
              dispatch(endDateTimePicked(newEndDate, lastTransmission));
            }}
          />
        </DateSelector>
      </Flex>
      <form>
        <RadioGroup
          aria-label="Time filters"
          onValueChange={(val) =>
            dispatch(rangePicked({ id: val, lastTransmission }))
          }
        >
          {rangeOptions.map((opt) => (
            <Flex
              key={opt.id}
              css={{ marginBottom: "6px", alignItems: "center" }}
            >
              <RadioGroupRadio
                value={opt.id}
                id={opt.id}
                checked={opt.selected}
              >
                <RadioGroupIndicator />
              </RadioGroupRadio>
              <Label htmlFor={opt.id}>{opt.label}</Label>
            </Flex>
          ))}
        </RadioGroup>
      </form>
    </div>
  );
};
