import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoggedIn,
  selectUserId,
  checkLoginStatus,
  handleLogin
 } from "./authSlice";
import styles from "./Auth.module.css";

export const AuthButton = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    dispatch(checkLoginStatus());
  }, [dispatch]);

  useEffect(() => {
    if (!loggedIn) {
      dispatch(handleLogin(loggedIn));
    }
  }, [dispatch, loggedIn]);

  return (
    <div
      className={styles.button}
      onClick={() => dispatch(handleLogin(loggedIn))}
    >
      {loggedIn ? "Logout" : "Login"} {loggedIn ? userId : ""}
    </div>
  );
};
