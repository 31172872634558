import { configureStore } from "@reduxjs/toolkit";
import mapReducer from "../features/map/mapSlice";
import authReducer from "../features/auth/authSlice";
import filterMenuReducer from "../features/filterMenu/filterMenuSlice";

export const store = configureStore({
  reducer: {
    map: mapReducer,
    auth: authReducer,
    filterMenu: filterMenuReducer,
  }
});
