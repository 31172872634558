import React from "react";
import { DateTime } from "luxon";
import * as Collapsible from "@radix-ui/react-collapsible";
import { CaretRightIcon, CaretDownIcon } from "@radix-ui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "../../theme/stitches.config.js";
import * as Portal from "@radix-ui/react-portal";
import { selectDevices, selectGateways } from "../map/mapSlice";
import { checkboxToggled } from "../map/mapSlice";
import { TimeFilters } from "./TimeFilters";
import { Checkbox } from "../../components/Checkbox";

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "0%",
  height: 30,
  width: 30,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  color: "black",
  '&[data-state="closed"]': { backgroundColor: "white" },
  '&[data-state="open"]': { backgroundColor: "white" },
  "&:hover": { backgroundColor: "white" },
  "&:focus": {
    boxShadow: `0 0 0 2px white`,
    borderColor: "white",
  },
});

const FilterMenuContainer = styled("div", {
  width: 312,
  padding: 10,
  background: "white",
  color: "black",
  textAlign: "left",
  maxHeight: 540,
  overflowY: "scroll",
});

const FilterMenuSection = styled("div", {
  marginBottom: 15,
});

const SectionTitle = styled("div", {
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 8,
});

export const FilterMenu = ({ container }) => {
  const dispatch = useDispatch();
  const devices = useSelector(selectDevices);
  const gateways = useSelector(selectGateways);

  const handleCheckboxChange = (id, filterType) => {
    dispatch(checkboxToggled({ id, filterType }));
  };

  const [devicesOpen, devicesSetOpen] = React.useState(true);
  const [gatewaysOpen, gatewaysSetOpen] = React.useState(false);

  return (
    <Portal.Root containerRef={container}>
      <FilterMenuContainer>
        <FilterMenuSection>
          <SectionTitle>Time</SectionTitle>
          <TimeFilters />
        </FilterMenuSection>

        <FilterMenuSection>
          <Collapsible.Root open={devicesOpen} onOpenChange={devicesSetOpen}>
            <SectionTitle>
              Devices
              <Collapsible.Trigger asChild>
                <IconButton>
                  {devicesOpen ? <CaretDownIcon /> : <CaretRightIcon />}
                </IconButton>
              </Collapsible.Trigger>
            </SectionTitle>
            <Collapsible.Content>
              <form>
                {devices.map((dev) => (
                  <div key={dev.id}>
                    <Checkbox
                      id={dev.id}
                      label={dev.label}
                      sublabel={dev.id}
                      checked={dev.checked}
                      onChange={() => handleCheckboxChange(dev.id, "devices")}
                    />
                  </div>
                ))}
              </form>
            </Collapsible.Content>
          </Collapsible.Root>
        </FilterMenuSection>

        <FilterMenuSection>
          <Collapsible.Root open={gatewaysOpen} onOpenChange={gatewaysSetOpen}>
            <SectionTitle>
              Gateways
              <Collapsible.Trigger asChild>
                <IconButton>
                  {gatewaysOpen ? <CaretDownIcon /> : <CaretRightIcon />}
                </IconButton>
              </Collapsible.Trigger>
            </SectionTitle>
            <Collapsible.Content>
              <form>
                {gateways.map((gtwy) => (
                  <div key={gtwy.id}>
                    <Checkbox
                      id={gtwy.id}
                      label={gtwy.label}
                      checked={gtwy.checked}
                      onChange={() => handleCheckboxChange(gtwy.id, "gateways")}
                    />
                  </div>
                ))}
              </form>
            </Collapsible.Content>
          </Collapsible.Root>
        </FilterMenuSection>
      </FilterMenuContainer>
    </Portal.Root>
  );
};
