import React from "react";
import { MapViewContainer } from "./features/map/Map";
import { AuthButton } from "./features/auth/Auth";
import globalStyles from "./theme/globalStylyes";
// import { FilterMenu } from "./features/filterMenu/FilterMenu";
import "./App.css";

function App() {
  globalStyles();
  return (
    <div className="App">
      <header className="App-header">
        <MapViewContainer />
        <AuthButton />
      </header>
    </div>
  );
}

export default App;
