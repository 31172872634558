export const ESRI_OAUTH_INFO = {
  appId: "ephPh366b8TspMw6",
  portalUrl: "https://tnc.maps.arcgis.com/",
  popup: false,
};

export const ESRI_MAP_DEFAULTS = {
  // center: [-119.75, 34], // SCI
  center: [-120.45, 34.5],
  zoom: 13,
};

export const AGO_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

// unused since it conflicts with the OAuth login
export const ESRI_API_KEY =
  "AAPK474cf7e4eb2a4e05b3c8e92f8dc644d2UbW-jHT" +
  "PSTbAA7bvSyO-4_n83Ch7xqkZvwQQfE03c9bukDSbrZI8R6kP8tdScdfk";

export const LORA_TRACKING_LAYER = {
  title: "Lora tracking",
  url:
    "https://services.arcgis.com/F7DSX1DSNSiWmOqh/arcgis/rest/services/" +
    "lora_tracking_3/FeatureServer/0",
  outFields: ["*"],
  renderer: {
    type: "simple", // autocasts as new SimpleRenderer()
    symbol: {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      size: 5,
      color: "black",
    },
    visualVariables: [
      {
        type: "color",
        // field: "rssi_1",
        valueExpression: `if ($feature.buffered==1) {return -999;}
          return $feature.rssi_1;`,
        stops: [
          { value: -999, color: "#888888" },
          { value: -120, color: "#cf597e" },
          { value: -100, color: "#eeb479" },
          { value: -80, color: "#9ccb86" },
          { value: -60, color: "#39b185" },
        ],
        legendOptions: {
          title: "RSSI",
        },
      },
    ],
  },
  popupTemplate: {
    title: "{label} ({dev})",
    expressionInfos: [
      {
        name: "localTime",
        title: "Local time",
        expression: `ToLocal($feature.tr_tm_utc)`,
      },
      {
        name: "buffered",
        title: "Buffered value",
        expression: `if ($feature.buffered==1) {return "buffered location";}
        return "LoRaWAN available";`,
      },
      {
        name: "display",
        title: "Some extra HTML for rx info",
        expression: `
        var prefix='iot-map-popup-';
        if ($feature.buffered==1) {
           return prefix + 'invisible';
        }
        return prefix + 'visible';`,
      },
      {
        name: "display_2",
        expression: `
        var prefix='iot-map-popup-';
        if (IsEmpty($feature.gateway_2)) { return prefix + 'invisible'; }
        return prefix + 'visible';`,
      },
      {
        name: "display_3",
        expression: `
        var prefix='iot-map-popup-';
        if (IsEmpty($feature.gateway_3)) { return prefix + 'invisible'; }
        return prefix + 'visible';`,
      },{
        name: "display_4",
        expression: `
        var prefix='iot-map-popup-';
        if (IsEmpty($feature.gateway_4)) { return prefix + 'invisible'; }
        return prefix + 'visible';`,
      },
      {
        name: "display_5",
        expression: `
        var prefix='iot-map-popup-';
        if (IsEmpty($feature.gateway_5)) { return prefix + 'invisible'; }
        return prefix + 'visible';`,
      },
    ],
    content: `
        <ul>
          <li><span>Time:</span> {expression/localTime}</li>
          <li><span>Status:</span> {expression/buffered}</li>
        </ul>
        <table class="{expression/display}">
          <tr>
            <th>Gateway name</th>
            <th>RSSI</th>
          </tr>
          <tr>
            <td>{gw_label_1} ({gateway_1})</td>
            <td>{rssi_1}</td>
          </tr>
          <tr class="{expression/display_2}">
            <td>{gw_label_2} ({gateway_2})</td>
            <td>{rssi_2}</td>
          </tr>
          <tr class="{expression/display_3}">
            <td>{gw_label_3} ({gateway_3})</td>
            <td>{rssi_3}</td>
          </tr>
          <tr class="{expression/display_4}">
            <td>{gw_label_4} ({gateway_4})</td>
            <td>{rssi_4}</td>
          </tr>
          <tr class="{expression/display_5}">
            <td>{gw_label_5} ({gateway_5})</td>
            <td>{rssi_5}</td>
          </tr>
        </table>`
  },
  maxTransmissionsAlert:
    "You have exceeded the maximum number of " +
    "transmissions the server can return.",
};

export const radioButtons = [
  { id: "custom", duration: undefined, label: "Custom range", selected: true },
  { id: "lastminute", duration: 60, label: "Last minute", selected: false },
  {
    id: "last10minues",
    duration: 600,
    label: "Last 10 minutes",
    selected: false,
  },
  { id: "lasthour", duration: 3600, label: "Last hour", selected: false },
  { id: "lastday", duration: 86400, label: "Last day", selected: false },
  { id: "last10day", duration: 864000, label: "Last 10 days", selected: false },
];
