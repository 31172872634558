import { styled } from "../theme/stitches.config.js";
import { violet, blackA } from "@radix-ui/colors";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  backgroundColor: "white",
  width: 15,
  height: 15,
  borderRadius: "100%",
  border: `1px solid ${blackA.blackA7}`,
  // boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&[data-state=unchecked]": {
    backgroundColor: violet.violet2,
  },
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": {
    outline: "none",
    boxShadow: `0 0 0 2px ${violet.violet7}`,
  },
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 9,
    height: 9,
    borderRadius: "50%",
    backgroundColor: violet.violet11,
  },
});

// Exports
export const RadioGroup = RadioGroupPrimitive.Root;
export const RadioGroupRadio = StyledRadio;
export const RadioGroupIndicator = StyledIndicator;
