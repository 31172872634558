// put most non-React and non-Redux code here
import ArcGISMap from "@arcgis/core/Map";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapView from "@arcgis/core/views/MapView";
import Expand from "@arcgis/core/widgets/Expand";
import { DateTime } from "luxon";
import "@arcgis/core/assets/esri/css/main.css";
import "@arcgis/core/assets/esri/themes/light/main.css";
import { mapViewInitialized } from "./mapSlice";
import {
  ESRI_MAP_DEFAULTS,
  LORA_TRACKING_LAYER,
  AGO_DATETIME_FORMAT,
} from "../../config";

export const createMapView = (viewRef, filterMenuRef, dispatch) => {
  // create map
  const map = new ArcGISMap({ basemap: "topo-vector" });
  // create view
  const view = new MapView({
    container: viewRef,
    map: map,
    ...ESRI_MAP_DEFAULTS,
  });
  view.when(() => dispatch(mapViewInitialized()));
  // add map UI elements
  const filterMenuExpand = new Expand({
    expandIconClass: "esri-icon-filter",
    expandTooltip: "Filter transmissions",
    view: view,
    content: filterMenuRef,
  });
  view.ui.add(filterMenuExpand, "bottom-right");
  // create and add lora layer
  const layer = new FeatureLayer(LORA_TRACKING_LAYER);
  // console.log("DEBUG: layer title:::", layer.title);
  map.add(layer);
  return view;
};

export const getLayer = (view, layerName) => {
  let ret = view.map.allLayers.find((layer) => {
    return layer.title === layerName;
  });
  return ret;
};

export const buildIntervalFilter = (startDate, endDate) => {
  const fmt = AGO_DATETIME_FORMAT;
  const start = DateTime.fromISO(startDate).setZone("UTC").toFormat(fmt);
  const end = DateTime.fromISO(endDate).setZone("UTC").toFormat(fmt);
  return `(rec_tm_utc BETWEEN '${start}' AND '${end}')`;
};

export const buildHardwareFilter = (gateways, devices) => {
  const activeDevices = devices.filter((dev) => dev.checked);
  const activeGateways = gateways.filter((gtwy) => gtwy.checked);
  const devs = activeDevices.map((dev) => `'${dev.id}'`).join(", ");
  const gtwys = activeGateways.map((gtwy) => `'${gtwy.id}'`).join(", ");
  return (
    `((gateway_1 IN (${gtwys})) OR (gateway_2 IN (${gtwys})) OR ` +
    `(gateway_3 IN (${gtwys})) OR (gateway_4 IN (${gtwys})) OR ` +
    `(gateway_5 IN (${gtwys}))) AND (dev IN (${devs}))`
  );
};

export const paginatedQuery = async (layer, whereClause) => {
  // TODO: check whether we can start at the last page and move forwards
  const maxRecords = 50000; // TODO: move to config
  const maxRecordsPerPage = 2000;
  let page = 0;
  let receivedLastPage = false;
  let reachedMaxRecordCount = false;
  let features = [];
  while (!receivedLastPage && !reachedMaxRecordCount) {
    let query = layer.createQuery();
    query.start = page;
    query.num = maxRecordsPerPage;
    query.outFields = [
      "dev",
      "label",
      "gateway_1",
      "gateway_2",
      "gateway_3",
      "gateway_4",
      "gateway_5",
      "gw_label_1",
      "gw_label_2",
      "gw_label_3",
      "gw_label_4",
      "gw_label_5",
    ];
    query.where = whereClause;
    query.returnGeometry = false;
    const res = await layer.queryFeatures(query);
    features = features.concat(res.features);
    receivedLastPage = res.features.length < maxRecordsPerPage;
    reachedMaxRecordCount = features.length >= maxRecords;
    page++;
  }
  return features;
};
