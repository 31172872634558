import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  filterByTime,
  filterByHardware,
  getMetaData,
  getDevicesAndGateways,
  mapClicked,
  selectMapViewInitialized,
  selectDevices,
  selectGateways,
} from "./mapSlice";
import { selectLoggedIn } from "../auth/authSlice";
import { createMapView } from "./mapUtils";
import { FilterMenu } from "../filterMenu/FilterMenu";
import styles from "./Map.module.css";
import {
  selectBeginDateTime,
  selectEndDateTime,
} from "../filterMenu/filterMenuSlice";

export const MapViewContainer = () => {
  const dispatch = useDispatch();
  const mapViewInitialized = useSelector(selectMapViewInitialized);
  const loggedIn = useSelector(selectLoggedIn);
  const beginDateTime = useSelector(selectBeginDateTime);
  const endDateTime = useSelector(selectEndDateTime);
  const devices = useSelector(selectDevices);
  const gateways = useSelector(selectGateways);
  const [view, setView] = useState(null);
  const mapViewRef = useRef();
  const filterMenuContainerRef = useRef();

  // Create the map view
  useEffect(() => {
    if (!view) {
      setView(
        createMapView(
          mapViewRef.current,
          filterMenuContainerRef.current,
          dispatch
        )
      );
    }
    return () => {
      view && view.destroy();
    };
  }, [dispatch, view]);

  // Filter features by time interval
  useEffect(() => {
    if (view && loggedIn) {
      console.log("Map.js - dispatching filterByTime");
      console.log("INITIAL BEGIN DATETIME", beginDateTime);
      dispatch(filterByTime({ view, beginDateTime, endDateTime }));
    }
  }, [dispatch, beginDateTime, endDateTime, view, loggedIn]);

  // Filter features by selected hardware
  useEffect(() => {
    if (view && loggedIn && devices.length && gateways.length) {
      console.log("Map.js - dispatching filterByHardware");
      dispatch(filterByHardware({ view, gateways, devices }));
    }
  }, [dispatch, gateways, devices, view, loggedIn]);

  // Query on load
  useEffect(() => {
    if (view && loggedIn) {
      console.log("Map.js - dispatching getMetaData and getDevices");
      dispatch(getMetaData(view));
      dispatch(getDevicesAndGateways({ view, beginDateTime, endDateTime }));
    }
  }, [dispatch, view, loggedIn, beginDateTime, endDateTime]);

  return (
    <>
      <div
        ref={mapViewRef}
        className={styles.mapDiv}
        onClick={() => dispatch(mapClicked())}
      />
      <div ref={filterMenuContainerRef} />
      {mapViewInitialized && <FilterMenu container={filterMenuContainerRef} />}
    </>
  );
};
